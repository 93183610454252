@import url(https://fonts.googleapis.com/css?family=Varela+Round);
/* TO DO - bottom margin is white beflow  */

:root {
  /* font: 700 1em/1.4 poppins, Verdana, sans-serif; */
  font-family: 'Poppins', sans-serif;
  color: rgb(87, 88, 91);
}

* {
  box-sizing: border-box;
}

html {
  background-color: rgb(255, 255, 255);
}

body {
  overflow-x: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  position: relative;
}

.nav-linkHamburger {
  text-decoration: none;
  color: white;
  padding-top: 10px;
  /* margin-left: 10px; */
}

.nav-linkHamburger:hover {
  text-decoration: none;
  color: rgb(226, 147, 147);
  padding-top: 10px;
  margin-left: 10px;
}

.nav-linkHamburger:active {
  text-decoration: none;
  color: rgb(179, 66, 66);
  padding-top: 10px;
  margin-left: 10px;
}

.dropDown {
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  /* color: white;
  text-decoration: none; */
}

.dropDown a {
  color: white;
  text-decoration: none;
}

.hamburgerUnderline {
  text-decoration: underline;
}

.nav-link:hover {
  color: #f0324d;
}

.mobileCallIcon {
  position: fixed;
  bottom: 8%;
  right: 8%;
  z-index: 999;
}

.styleBurger {
  z-index: 998;
}

@media (min-width: 1026px) {
  .mobileCallIcon {
    display: none;
  }
}

.callUsText {
  margin-top: 4rem;
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.redText {
  color: rgb(240, 50, 77);
}

.redNumText {
  font-size: 18px;
  font-weight: 1000;
  color: rgb(240, 50, 77);
}

.addressText {
  margin-top: -0.75rem;
  font-size: 10px;
}

.freeText {
  font-size: 40px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 900;
  text-align: left;
  margin: 0;
}

.squiggleImg {
  height: 12px;
  width: 50px;
  /* padding-top: 4rem; */
  /* background-image: url('./images/squiggle.png;'); */
}

.oilChangeTextBox {
  /* margin-top: -1.5rem; */
  text-align: left;
  display: flex;
  flex-direction: column;
  width: 55%;
  justify-content: space-around;
  /* padding-top: 0; */
}

/* .carouselBox {
  padding: 0;
} */

.napaSquiggleBox {
  /* margin-top: -1.5rem; */
  text-align: right;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 8%;
  justify-content: flex-end;
  margin-top: 5.5rem;
  align-self: flex-end;
}

.oilChangeText {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  font-size: 30px;
  color: black;
  font-weight: bold;
}

.smallText {
  font-size: 12px;
  padding-top: 6px;
  margin: 0;
  font-family: 'Quicksand', sans-serif;
}

li {
  list-style: none;
  font-family: 'Poppins', sans-serif;
}

.VeteranSubscribeBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 20%;
  background-color: 'transparent';
}

.moveVeteranText {
  margin-left: 250px;
  margin-top: 50px;
  text-align: left;
  line-height: 1;
}





/* .napaBackgroundLocationtest {
  position: absolute;
  left: 550px;
  top: 175px;
  font-size: 1.1rem;
  font-weight: 900;
} */

.napaBackgroundLocation {
  margin: 0;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  -webkit-font-variant-ligatures: normal;
          font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 900;
  font-stretch: normal;
  font-size: 1rem;
  /* line-height: 1.5em; */
  font-family: Intro, Verdana, sans-serif;
}

.flexThis {
  display: flex;
  flex-direction: row;
  /* color: red; */
  text-decoration: none;
  align-items: flex-end;
}

.nav-link>img {
  padding-bottom: 0;
  margin-bottom: 10px;
  border: solid red 2px;
}

.flexThis a {
  display: flex;
  flex-direction: row;
  color: red;
  text-decoration: none;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 20px;
  text-transform: uppercase;
}

.submitContainer {
  display: flex;
  flex-direction: row;
  /* border: solid blue 3px; */
  justify-content: left;
  width: 100%;
  margin-top: 7px;
  margin-bottom: 5px;
}

.crumbContainer {
  /* display: flex;
  /* width: 45%; */
  /* justify-content: center; */
  /* margin-bottom: -5rem; */
  /* height: 222px;
  position: absolute;  */
}

.pageContainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .veteranInputBox {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 80%;
  justify-content: space-evenly;
  padding: 0;
  margin-left: 20px;
  border: solid green 3px;
  background-color: 'transparent';
} */

.inputField {
  height: 30px;
  width: 20%;
  font-family: 'Poppins', sans-serif;
  font-size: 8px;
  border: none;
  box-shadow: 0 0 25px 0 rgba(179, 179, 179, 1);
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  /* margin-bottom: 0.5rem; */
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.submitBox {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.subscribeButton {
  background-color: black;
  border: none;
  border-radius: 4px;
  color: white;
  /* padding: 13px 20px 30px 20px; */
  height: 30px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* width: 20%; */
  font-size: 14px;
  /* border: solid pink 3px; */
  font-family: 'Quicksand', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
}

.subscribeButton2 {
  background-color: black;
  border: none;
  border-radius: 4px;
  color: white;
  /* padding: 13px 20px 30px 20px; */
  height: 30px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* width: 20%; */
  font-size: 14px;
  /* border: solid pink 3px; */
  font-family: 'Quicksand', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  margin-left: 290px;
}

.homeSchedulerSubmitButtonContainer {
  display: flex;
  justify-content: center;
  /* border: solid green 4px; */
}

.homeAppointmentButtonDiv {
  background-color: black;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
  color: white;
  font-size: 13px;
  font-weight: 1000;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  width: 150px;
}

.homeSchedulerReply {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
}

.homeAppointmentButtonDiv:hover {
  background-color: rgb(240, 50, 77);
}

.homeAppointmentButtonDiv:active {
  background-color: rgb(182, 43, 64);
}

.subscribeButton2:hover {
  background-color: rgb(240, 50, 77);
}

.subscribeButton2:active {
  background-color: rgb(182, 43, 64);
}

.subscribeButton:hover {
  background-color: rgb(240, 50, 77);
}

.subscribeButton:active {
  background-color: rgb(182, 43, 64);
}

.submitBox a {
  /* width: 150px; */
  margin: 15px 0 40px 0;
  /* margin-bottom: 10px; */
  padding-right: 10px;
  padding-left: 10px;
  /* font-size: 12px; */
}

.subscribeButton {
  /* background-color: black;
  border: none;
  border-radius: 4px;
  color: white;
  height: 30px;
  width: 120px;
  justify-content: center;
  display: flex;
  font-size: 90px;
  border: solid pink 3px;
  font-family: 'Poppins', sans-serif; */
}

.subscribeButton a {
  /* background-color: black;
  border: none;
  border-radius: 4px;
  color: white;
  height: 30px;
  width: 200px;
  font-size: 90px;
  border: solid pink 3px;
  font-family: 'Poppins', sans-serif; */
}

.subscribeButton:hover {
  background-color: rgb(240, 50, 77);
}

.innerDiv {
  /* display: flex; */
  /* justify-content: center; */
  /* flex: 1; */
  font-size: 1.5rem;
  /* margin-left: 60px; */
}

/* .innerDiv:last-child {
  margin-right: 60px;
} */

.tips-advice,
.coupons,
.services {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
}

.tips-advice {
  background-image: url('/images/tips-and-advices.jpg');
}

.coupons {
  background-image: url('/images/our-coupons.jpg');
}

.services {
  background-image: url('/images/our-services.jpg');
}

.innerDiv h2 {
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  padding-bottom: 10px;
}

.innerDiv h2::after {
  position: relative;
  top: 10px;
  right: -25%;
  content: '';
  width: 100%;
  height: 40px;
  border: thick solid transparent;
  border-top-color: red;
}

.innerDiv h2 em {
  color: red;
  font-style: normal;
  font-size: 3rem;
  line-height: 2rem;
}

.innerDiv .cta {
  font-size: 1rem;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
/* 
.innerDiv .cta .button {
} */

.subscribeButton:hover {
  background-color: rgb(240, 50, 77);
}

.subscribeButton:active {
  background-color: rgb(182, 43, 64);
}

.redeemBox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  width: 140px;
  padding-bottom: 5rem;
}

.redeemContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.redeemButton {
  background-color: black;
  border: none;
  border-radius: 4px;
  color: white;
  height: 30px;
  width: 80%;
  font-size: 9px;
  font-weight: 1000;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}

.redeemButton2 {
  background-color: black;
  border: none;
  border-radius: 4px;
  color: white;
  /* height: 30px; */
  width: 80%;
  font-size: 13px;
  font-weight: 1000;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.redeemButton2:hover {
  background-color: rgb(240, 50, 77);
}

.redeemButton2:active {
  background-color: rgb(182, 43, 64);
}

.redeemButton:hover {
  background-color: rgb(240, 50, 77);
}

.redeemButton:active {
  background-color: rgb(182, 43, 64);
}

.specialAd {
  /* width: 50%; */
}

.smallLink {
  font-size: 8px;
}

.nextBox {
  background-image: url('/images/newcarcollage.jpg');
  height: 10rem;
  width: 100%;
  background-size: cover;
}

.specialAd {
  display: flex;
  /* border: solid black 4px; */
}

.homepageSpecialsContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: flex-end; */
  align-items: right;
  /* margin-left: auto; */
  width: 100%;
  /* border: solid green 3px; */
  /* justify-content: right; */
  /* text-align: right; */
  padding: 10px;
}

.homepageSpecialsBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* border: solid blue 3px; */
}

.homepageSpecialsText {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* border: solid blue 3px; */
  text-align: right;
}

.homepageSpecialsTitle {
  margin-top: 0.5rem;
  font-weight: 900;
  margin-bottom: -1.6rem;
  text-align: right;
}

/* .reviewBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: solid blue 2px; */

.reviewText {
  text-decoration: underline;
  text-align: center;
  font-size: 13px;
  /* display: flex; */
}

.vehicleInfo {
  /* text-decoration: underline; */
  text-align: left;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  flex: 3.5 1;
  /* align-items: center; */
  margin-left: 80px;
}

.vehicleList {
  list-style: none;
  /* line-height: 1.1; */
}

.vehicleList li::before {
  content: '\2022';
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.vehicleInfo li {
  padding: 5px;
  font-size: 20px;
  color: black;
}
.bottomFooter {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgb(25, 25, 25);
  padding-top: 3rem;
  /* padding-left: 20rem; */
  padding-bottom: 4rem;
  /* border: solid red 3px; */
  justify-content: center;
  margin: 0;
}

.footerLogo {
  display: flex;
  justify-content: center;
  width: 20%;
  align-items: initial;
  /* border: solid green 2px; */
  padding: 0;
  margin: 0;
  margin-top: -10px;
}

.whiteTitle {
  color: white;
  font-weight: 1000;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  margin-bottom: 1rem;
  text-align: center;
}

.contactFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style: none;
  width: auto;
  /* border: solid white 3px; */
  margin: 10px;
}

.footerList {
  /* border: solid purple 2px; */
  margin: 0;
  padding: 0;
}

.footerList li {
  font-size: 12px;
  margin-bottom: 8px;
}

.footerNav {
  list-style: none;
  /* display: flex;
  justify-content: center;
  flex-direction: column; */
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /* border: solid white 3px; */
}

.bottomNavList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  list-style: none;
  font-size: 0.75rem;
  margin: 0;
  /* margin-left: 40px; */
  padding: 0;
  max-width: 320px;
  width: 200px;
}

.bottomNavList li {
  padding-bottom: 15px;
}

.bottomNavList li a:hover {
  text-decoration: underline;
  color: white;
}

.footerList li {
  color: grey;
}

.footerLinks {
  text-decoration: none;
  color: grey;
  margin-top: 5rem;
  /* width: 50%; */
}

.servicePageLinks {
  color: black;
  text-decoration: none;
  list-style: disc;
  /* width: 50%; */
}


.servicePageLinks a {
  color: black;
  text-decoration: none;
  list-style: disc;
  /* width: 50%; */
}

.serviceListItem {
  color: black;
  text-decoration: none;
  list-style: disc;
  /* width: 50%; */
}

.servicePageLinks:hover {
  text-decoration: underline;
  color: grey;
  margin-top: 5rem;
  font-weight: 900;
  /* width: 50%; */
}


.whiteBold {
  color: white;
  font-weight: 200;
  font-size: 0.8rem;
  padding-right: 0.7rem;
}

/* 
.bottomBar {
  display: flex;
  flex-direction: row;
} */

.testDiv {
  position: absolute;
}

.control-dots {
  margin: 0;
  padding: 0;
}

.carouselButton {
  margin: 0;
  margin-bottom: 4rem;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
  /* position: absolute;
  left: 693px;
  top: 280px; */
  background-color: white;
  border: none;
  border-radius: 7px;
  color: navy;
  /* height: 40px;
  width: 140px; */
  font-size: 18px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  transition: all 0.5s ease 0s;
  transition: color 0.5s ease 0s;
}

.carouselButton:hover {
  color: white;
  background-color: #f0324d;
  transition-delay: 0.1s;
  -webkit-transition-delay: 0.1s;
}

.warrantyImg {
  object-fit: cover;
}

.warrantyTextContainer {
  /* line-height: 3; */
  margin-bottom: 60px;
}

.warrantyTextInnerDiv {
  border: solid green 2px;
}

.warrantyCarouselText {
  /* position: absolute;
  left: 270px;
  top: 100px; */
  color: white;
  /* font-size: 0.1em;
  transform: scale(1.5, 1.8); */
  font-family: 'Poppins', sans-serif;
  font-size: 400em/1.5em;
  /* width: 40%;
  text-align: right; */
  font-weight: 100;
  margin-top: 40px;
  font-size: 20px;
}

.napaBackgroundTitle {
  margin: 0;
  color: white;
  /* position: absolute; */
  /* left: 40rem;
  top: 2rem; */
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  -webkit-font-variant-ligatures: normal;
          font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 900;
  font-stretch: normal;
  font-size: 2.2em;
  /* line-height: 1.5em; */
  font-family: Intro, Verdana, sans-serif;
  text-transform: uppercase;
}

.warrantyCarouselTitle {
  /* position: absolute;
  left: 270px;
  top: 20px; */
  color: white;
  /* font-size: 0.1em;
  transform: scale(1.5, 1.8); */
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  /* width: 40%;
  text-align: right; */
  font-weight: 100;
}

.yellowText {
  text-decoration: underline;
  color: #faad14;
  margin-bottom: 0;
}

.napaDiv {
  text-align: center;
  /* border: solid green 3px; */
  display: flex;
}

.dropdownNavContent {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdownNavContent a {
  font-size: 13px;
  color: white;
  padding: 6px 16px;
  text-decoration: none;
  font-weight: 700;
  display: block;
}

.dropdownNavContent a:hover {
  /* background-color: #ddd; */
  color: #f0324d;
}

.dropdownNav:hover .dropdownNavContent {
  display: block;
}

.dropdownNav:hover .dropdownServicesButton {
  color: #f0324d;
}

.dropdownNav {
  text-decoration: none;
  color: rgb(87, 88, 91);
  position: relative;
  display: inline-block;
  padding-top: 10px;
  margin-left: 5%;


  /* margin-left: 30px;
  margin-right: 30px; */
  /* margin-left: 2rem; */
}

.nav-link {
  text-decoration: underline;
  color: rgb(87, 88, 91);
  padding-top: 10px;
  margin-left: 5%;
  /* font-size: 17px; */
  font-weight: 900;
  /* margin-left: 30px;
  margin-right: 30px; */
  /* margin-left: 10px; */
}


a.dropdownServicesButton {
  font-size: 18px;
}

a.nav-link {
  font-size: 18px !important;
}

@media (max-width: 1550px) {

  a.dropdownServicesButton {
    font-size: 14px;
    margin-bottom: 100px;
    /* border: solid red 2px; */
  }
  
  a.nav-link {
    font-size: 14px;
  }
}

.topNumberLink {
  text-decoration: none;
}

.dropdownServicesButton {
  background-color: rgb(255, 255, 255);
  color: rgb(87, 88, 91);
  font-size: 18px !important;
  border: none;
  text-decoration: underline;
  font-weight: 900;

}

.bottomRightHalfDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
  margin-left: -80px;
  /* padding-right: 0; */
  /* padding-right: 5rem; */
  padding-top: 50px;
  /* padding-bottom: 20px; */
  /* margin-bottom: -10px; */
}



.greyLink {
  color: black;
  text-decoration: none;
  font-size: 20px;
}

.greyLink:hover {
  text-decoration: underline;
  font-weight: 900;

}



.burgerLink {
  color: white;
}

.servicesHamburgerDropDown {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.servicesHamburgerDropdown:active {
  color: #f0324d;
}

/* CSS for AdviceServiceCouponComponent */

.event-info {
  padding: 10px;
}

.events-box {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1.5rem;
  /* color: black; */
  border: solid rgb(65, 159, 173) 4px;
  background-color: rgb(166, 224, 166);
  font-size: 28px;
  list-style: none;
  /* height: 7rem; */
  align-items: center;
  margin-bottom: 10px;
}

.eventsList1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 1.5rem;
  color: black;
  /* border: solid rgb(65, 159, 173) 4px; */
  /* background-color: rgb(166, 224, 166); */
  font-size: 28px;
  list-style: none;
  /* height: 7rem; */
  align-items: center;
}

.eventsList1 li {
  margin: auto 20px;
}

/* .event-info {
  margin-left: 6rem;
  margin-right: 6rem;
  background-color: rgba(255, 255, 255, 0.7);

  z-index: 10;
} */

p {
  /* margin-right: 2rem; */
}

.creditsFooter {
  border-top: thin white 5px;
}

.homeScheduler {
  margin-top: 5rem;
  width: 100%;
  /* border: solid purple 2px; */
  /* display: flex; */
}

.appointmentTitle {
  display: flex;
  width: 90%;
  font-size: 14px;
  margin-bottom: 0;
}

.topHalf {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  /* border: solid green 2px; */
  /* height: 500px; */
}

.homeAppointmentInstructions {
  text-align: right;
  /* border: solid yellow 3px; */
  color: rgb(240, 50, 77);
  line-height: 1.5;
  /* width: 30%; */
  flex: 1 1;
  /* font-size: 6px; */
}

.homeAppointmentPhoto {
  /* border: solid blue 2px; */
  background-image: url('/images/bookappointment.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  /* border: solid purple 3px; */
  flex: 1 1;
}

.leftArea {
  flex: 1 1;
  /* border: solid blue 3px ; */
}

.rightArea {
  /* border: solid green 3px; */
  flex: 1 1;
}

.homeScheduleInnerDiv {
  /* border: solid black 2px; */
  max-width: 900px;
}

.homeScheduleInnerDiv .row {
  display: flex;
  justify-content: center;
}

.homeScheduleContainer {
  /* margin-top: 1.2rem; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* border: solid purple 2px; */
  /* height: 20rem; */
  width: 100%;
}

.lineInput {
  background: transparent;
  font-size: 18px;
  color: grey;
  border: none;
  width: ;
}

.lineInput:focus {
  background: transparent;
  /* border-bottom: 1px solid rgb(240, 50, 77); */
  color: red;
}

.homeScheduleInnerDiv .control {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  margin: 10px;
  border-bottom: thin solid black;
}

.homeScheduleInnerDiv .control.double {
  flex: 2 1;
}

.homeScheduleInnerDiv label {
  text-transform: uppercase;
  font-size: 0.8em;
  margin: 0 1em 1em 0;
}

.homeAppointmentReasonContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.homeAppointmentReasonDiv {
  /* border: solid green 2px; */
  width: 65.3%;
  justify-content: flex-start;
  display: flex;
  margin-top: 10px;
}

.homeAppointmentField {
  width: 100%;
  background: transparent;
  border: none;
  border-bottom: 1px solid grey;
  font-size: 18px;
  color: grey;
}

.homeAppointmentField:focus {
  background: transparent;
  border: none;
  /* border-bottom: 1px solid rgb(240, 50, 77); */
  color: red;
}

.breadCrumbDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: auto;
  flex: 1 1;
  width: 78%;
}

.carTable {
}

.breadCrumbTextt {
  color: red;
  text-decoration: none;
}

/* .breadCrumbTextFirst {
  margin-left: 0;
  margin: 0;
  padding: 0;
  border: solid yellow 4px;
} */

.homeBreadCrumb a {
  margin-left: 0;
}



.imgDiv {
  margin: 0;
  height: 0;
  margin-left: -80px;
  padding-right: 60px;
  border: solid red 2px;

  padding-top: 10px;
  padding-bottom: 20px !important;
  margin-bottom: 40px !important;
}

.nav-link-img {
  height: 175px;
  width: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.reviewTable {
  /* border: solid red 2px; */
  /* display: flex; */
  width: 90%;
  margin-top: 1rem;
}

.vehicleImgContainer {
  display: flex;
  justify-content: center;
  /* border: solid green 2px; */
  padding-left: 200px;
}

@media (max-width: 930px) {
  .vehicleImgContainer {
    padding-left: 0;
  }
}

@media (max-width: 400px) {
  .scheduleTitles {
    line-height: 4;
  }
}
.directionsDiv {
  display: flex;
  justify-content: center;
}

@media (max-width: 840px) {
  .directionsImg {
    width: 400px;
    height: 260px;
  }
}

@media (max-width: 450px) {
  .directionsImg {
    width: 200px;
    height: 130px;
    justify-content: center;
    display: flex;
  }
}

@media (max-width: 730px) {
  .vehicleList li {
    font-size: 12px;
  }

  .vehicleInfo {
    margin-left: 0;
  }

  .imgContainer {
    height: 100px;
    width: 100px;

  }

  .imgPhotoSection {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: solid purple 5px; */
    height: 100%;
    /* padding-left: 50px; */
  }

  .bottomFooter {
    width: 100%;
    /* margin-right: 200px; */
  }

  .reviewText {
  }

  .reviewText li {
    font-size: 10px;
  }

  .reviewInfo li {
    font-size: 12px;
  }

  .starsImg img {
    height: 10px;
    width: 50px;
  }
}

.reviewList {
  display: flex;
  justify-content: center;
  /* border: solid green 2px; */
  padding-left: 20px;
  /* width: 100%; */

  /* text-align: left; */
  /* flex-direction: row; */
  /* align-items: flex-start; */
  /* width: 100%; */
}

.reviewInfo {
  border-right: solid rgba(180, 180, 180, 0.5) 1px;
  padding-right: 10px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  font-size: 8px;
  font-weight: 900;
  width: 100%;
  /* border: solid purple 12px; */
}

.reviewBox {
  border-top: solid rgb(180, 180, 180, 0.5) 1px;
  border-bottom: solid rgb(180, 180, 180, 0.5) 1px;
  display: flex;
  margin-bottom: 1.5px;
  /* border: solid black 2px; */
  width: 100%;

  /* text-align: left; */
}

.starsImg {
  display: flex;
  justify-content: center;
  /* width: 100%; */
}

.reviewInfo {
  border-right: solid rgba(180, 180, 180, 0.5) 1px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  font-weight: 900;
}

.reviewInfo li {
  margin-bottom: 2px;
}

.reviewText {
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}

.lineInput {
  /* border: solid purple 4px; */
  /* height: 5rem; */
}

.hey {
  border-bottom: 1px solid grey;
}

.greyText {
  color: grey;
}

.reasonField {
  height: 60px;
}

textarea,
select,
input,
button {
  outline: none;
}

textarea {
  outline: none;
  resize: none;
  height: 50px;
}

.customBreadCrumbSpacing {
  margin: 0;
  padding: 0;
}

.scheduleCalendar {
  width: 50%;
  color: red;
  font-size: 16px;
}
.scheduleCalendar2 {
  width: 50%;
  border: none;
  color: red;
  font-size: 16px;
}

.scheduleInputDiv {
  display: flex;
}

.scheduleTitles {
  display: flex;
  width: 90%;
  justify-content: space-between;
}

.warningBox {
  border: solid black 1px;
  border-radius: 4px;
  margin-top: 0.75rem;
  line-height: 0.75;
  padding: 10px 14px 10px 10px;
  font-size: 13px;
  background-color: rgb(254, 255, 206);
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  width: 46%;
}

.emailContainer input,
.nameContainer input,
.phoneContainer input {
  border: solid 1px rgba(110, 110, 110, 0.438);
  border-radius: 2px;
  height: 10px;
}

.phoneContainer {
  display: flex;
}

.phoneEmptyDiv {
  flex: 0.5 1;
}

.phoneInput {
  display: flex;
  width: 100%;
}

.emailContainer {
  display: flex;
}

label {
  line-height: 0.5;
}

.seniorsTitle {
  text-align: center;
  padding-bottom: 5rem;
}

.appointmentSendButton:hover {
  background-color: rgb(240, 50, 77);
}

.appointmentSendButton:active {
  background-color: rgb(182, 43, 64);
}

.resumeSubmitContainer {
  display: flex;
  justify-content: center;
  width: 120px;
  font-size: 120px;
}

.affiliateLogos {
  margin: 0.75rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.affiliateLogos a {
  margin-left: 10px;
  margin-right: 10px;
}

.ada-icon {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.phoneNumber {
  font-size: 18px;
  
}

.largeNumber {
  font-size: 30px;
  margin-left: 8px;
  font-weight: 900;
}

.contactFooter a {
  text-decoration: none;
}

.mobileContactBox a {
  text-decoration: none;
}

.socialMediaFooter {
  margin: 10px;
  width: auto;
  /* border: solid green 2px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialLogoIcons {
  display: flex;
}

.dropdownNavContent a {
  text-transform: uppercase;
}

.contactUsContainer h3 {
  text-transform: uppercase;
}

.contactSendButton {
  width: 100%;
  /* border: solid purple 3px; */
  display: flex;
  justify-content: center;
}

.specialsRedeemButton {
  width: 100%;
  /* border: solid purple 3px; */
  display: flex;
  justify-content: center;
}

.contactSendDiv {
  width: 80%;
  /* border: solid purple 3px; */
  display: flex;
  justify-content: center;
}

.specialsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: -27%;
}

.specialsBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: right;
  line-height: 1;
  border: thin grey solid;
  flex: 1 1;
  margin-left: 16rem;
  margin-top: 3rem;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 5px;
}

.specialsSpacer {
  flex: 4 1;
  /* border: solid green 2px; */
  height: auto;
}

.specialsActions {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  height: 40px;
  margin: 0;
  padding: 0;
}

.specialsActions h2 {
  margin: 0;
  padding: 0;
  font-size: 40px;
}

.contactUsCompleteDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.welcomeText {
  line-height: 0.1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
}

.welcomeText h1 {
  font-size: 80px;
  color: white;
  text-transform: uppercase;
}

.glowTitle {
  text-shadow: 1px 1px 2px black, 0 0 25px rgb(255, 255, 0),
    0 0 5px rgb(184, 184, 13);
}

.blueGlow {
  text-shadow: 1px 1px 2px black, 0 0 25px rgb(9, 125, 160),
    0 0 5px rgb(184, 184, 13);
}

.welcomeText h3 {
  font-size: 22px;
  text-decoration: underline;
  color: white;
  text-transform: uppercase;
}

@media (max-width: 1720px) {
  .welcomeText {
    margin-top: 20rem;
  }

  .welcomeText h1 {
    font-size: 60px;
  }

  .welcomeText h3 {
    font-size: 18px;
  }
}

@media (max-width: 1200px) {
  .welcomeText {
    margin-top: 28rem;
  }

  .welcomeText h1 {
    font-size: 50px;
  }
}

@media (max-width: 1500px) {
  .mobileColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .warrantyCarouselText {
    font-size: 18px;
  }

  .warrantyCarouselTitle {
    font-size: 18px;
  }
}

@media (max-width: 1400px) {
  .warrantyCarouselText {
    font-size: 14px;
  }

  .warrantyCarouselTitle {
    font-size: 16px;
  }
}

@media (max-width: 1120px) {
  .warrantyCarouselText {
    font-size: 12px;
    line-height: 2;
    margin-top: 10px;
  }

  .warrantyCarouselTitle {
    font-size: 14px;
  }

  .carouselButton {
    margin-top: 10px;
    margin: 0;
    margin-bottom: 100px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 10px;
  }
}

@media (max-width: 1000px) {
  .warrantyCarouselText {
    font-size: 8px;
    line-height: 2;
  }
  .warrantyCarouselTitle {
    font-size: 10px;
  }

  .carouselButton {
    margin-top: 10px;
  }
}

@media (max-width: 850px) {
  .warrantyCarouselText {
    font-size: 6px;
  }

  .carouselButton {
    margin-top: 10px;
    margin-bottom: 100px;
  }
}

@media (max-width: 600px) {
  .mediaImg {
    height: 175px;
    width: 225px;
  }
  .welcomeText {
    justify-content: center;
    align-items: center;
    max-width: 400px;
  }

  .welcomeText h1 {
    font-size: 30px;
  }

  .welcomeText h3 {
    font-size: 12px;
  }

  .topHalf {
    display: flex;
    flex-direction: column;
    /* border: solid green 2px; */
    /* height: 500px; */
  }

  .hideInMobile {
    display: none;
  }
}

.mobileHeader {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

@media (max-width: 820px) {
  .bottomFooter {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: rgb(25, 25, 25);
    padding-top: 3rem;
    /* padding-left: 20rem; */
    padding-bottom: 4rem;
    justify-content: center;
    align-items: center;
  }

  .bottomNavList {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    font-size: 0.7rem;
    margin: 0;
    padding: 0;
    /* max-width: 899px; */
    width: 100%;
  }
}
@media (max-width: 700px) {
  .affiliateLogos {
    display: flex;
    flex-direction: column;
    margin: 50px;
  }
}

@media (max-width: 850px) {
  .hideWhenMobile {
    display: none;
  }
}

.mobileAppointmentButton {
  width: 50%;
  /* margin-top: 10px; */
  margin-bottom: 50px;
}

.mobileAppointmentButton a {
  height: 50px;
  width: 100%;
  font-size: 20px;
}

@media (min-width: 850px) {
  .mobileAppointmentButton {
    display: none;
  }
}

@media (max-width: 600px) {
  .mobileAppointmentButton a {
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  .mobileAppointmentButton a {
    font-size: 12px;
  }
}

@media (max-width: 350px) {
  .mobileAppointmentButton {
    height: 40px;
    width: 200px;
  }

  .mobileAppointmentButton a {
    font-size: 12px;
  }
}

@media (max-width: 1700px) {
  .affiliateContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .affiliateLogos {
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
    width: 70%;
  }
}

@media (max-width: 1200px) {
  .oilChangeTextBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    text-align: center;
  }

  .affiliateResize {
    display: flex;
    flex-direction: column;
  }

  .subscribeButton2 {
    margin: 0;
    padding: 0;
    margin-top: 20px;
  }
}

.affiliateLogos a {
  margin: 15px;
}

.amsoilLogo {
  height: 45px;
}

.aseLogo {
}

.atiLogo {
  height: 130px;
}

.bgLogo {
}

.napaLogo {
}

.jasperLogo {
  height: 50px;
}

.boschLogo {
  height: 35px;
}

.snaponLogo {
  height: 30px;
}

.boschLogo {
}

.carquestLogo {
  height: 70px;
}

.rcLogo {
  height: 50px;
}

.michelinLogo {
  height: 30px;
}


.vetTopHeader {
  margin: 0;
  color: white;
  /* position: absolute; */
  /* left: 40rem;
  top: 2rem; */
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  -webkit-font-variant-ligatures: normal;
          font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 800;
  font-stretch: normal;
  font-size: 4.5em;
  /* line-height: 1.5em; */
  font-family: Intro, Verdana, sans-serif;
  text-transform: uppercase;
}

.vetNextHeader {
  margin: 0;
  color: white;
  /* position: absolute; */
  /* left: 40rem;
  top: 2rem; */
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  -webkit-font-variant-ligatures: normal;
          font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 900;
  font-stretch: normal;
  font-size: 3.5em;
  /* line-height: 1.5em; */
  font-family: Intro, Verdana, sans-serif;
  text-transform: uppercase;
}

.vetContainer {
  /* border: solid pink 4px; */
}

.vetText {
  color: white;
  font-weight: 600;
  font-size: 24px;
  margin-left: 7px;
}

.vetSubText {
  color: white;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  margin-left: 7px;

}







@media (max-width: 1400px) {
  .vetTopHeader {
    font-size: 66px;
    margin-left: 2.2rem;
    line-height: 0.75;
  }
.vetNextHeader {
  font-size: 50px;
  margin-left: 2.2rem;
}

.vetText {
font-size: 24px;
margin-left: 2.4rem;
margin-top: 1rem;
}

.vetSubText {
  font-size: 16px;
  margin-left: 2.4rem;
}

}


@media (max-width: 1100px) {
  .vetTopHeader {
    font-size: 46px;
    margin-left: 2.2rem;
    line-height: 0.75;
  }
.vetNextHeader {
  font-size: 42px;
  margin-left: 2.2rem;
}

.vetText {
font-size: 18px;
margin-left: 2.4rem;
margin-top: 0;
}

.vetSubText {
  font-size: 14px;
  margin-left: 2.4rem;
}

}

@media (max-width: 808px) {
  .vetTopHeader {
    font-size: 38px;
    margin-left: .6rem;
    line-height: 0.75;
  }
.vetNextHeader {
  font-size: 32px;
  margin-left: .6rem;
}

.vetText {
font-size: 15px;
margin-left: 1rem;
margin-top: 0;
}

.vetSubText {
  font-size: 11px;
  margin-left: 1rem;
}

}

/* Review Component & Page CSS */

.reviewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 5rem;
}

.reviewInnerContainer {
  width: 40%;
  /* border: solid blue 2px; */
}

.star-rating {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.star {
  cursor: pointer;
  width: 2em;
  height: 2em;
  background-color: grey;
  -webkit-clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0% 38%,
    37% 38%
  );
  clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0% 38%,
    37% 38%
  );
}

.star.selected {
  background-color: rgb(247, 89, 89);
}

p {
  /* flex-basis: 100%; */
  /* text-align: center; */
}

.reviewTitle {
  text-align: center;
  font-size: 50px;
}

.reviewTitle:hover {
  color: rgb(148, 40, 40);
}

.testPageColor {
  background-color: blue;
}

.AnotherTestPage {
  background-color: blue;
}

.starCount {
  margin-top: 2rem;
}



.reviewSubmitButton {
  background-color: black;
  border: none;
  border-radius: 4px;
  color: white;
  height: 30px;
  width: 100px;
  /* width: 80%; */
  font-size: 12px;
  font-weight: 1000;
  font-family: 'Poppins', sans-serif;
  margin-top: 1.5rem;
  /* text-transform: uppercase; */
}

.specialsBox a {
  width: 250px;
}

.reviewSubmitButton:hover {
  background-color: rgb(240, 50, 77);
}

.reviewSubmitButton:active {
  background-color: rgb(182, 43, 64);
}

.manualPaddingDiv {
  margin: 0;
  padding: 0;
  padding-left: 4rem;
}



.testContainer {
  display: flex;
  width: 90%;
  /* border: solid purple 5px; */
  justify-content: flex-start;
}

.reviewTopHalfTotal {
  display: flex;
  flex-direction: row;
  /* border: solid green 2px; */
  width: 100%;
  /* justify-content: space-between; */
}

.reviewTopHalfDivUpper {
  display: flex;
  flex-direction: column;
  /* border: solid blue 2px; */
  flex: 2 1;
  width: 100%;
}

.reviewTopHalfDivUpper p {
  padding: 0;
  margin: 0;
  margin-top: 40px;
  margin-left: 2%;
}

.navReviewFlex {
  flex-direction: column;
  display: flex;
  flex: 2 1;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: -20px;
}

.navReviewFlex p {
  margin: 0;
  font-size: 20px;
}

.rightHalfDiv {
  display: flex;
  flex-direction: column;
  /* border: solid pink 3px; */
  justify-content: flex-end;
  width: 100%;
}

.topRightHalfDiv {
  display: flex;
}

.navStars {
  padding-right: 10px;
  margin-left: 2%;
}

.flexThis {
  /* margin-left: 14%; */
  margin-bottom: 0;
}

.underlineText {
  text-decoration: none;
  color: rgb(87, 88, 91);
}

.reviewText {
  color: rgb(87, 88, 91);
}

.underline {
  text-decoration: underline;
}

.underlineText:hover {
  text-decoration: none;
}

.reviewSubmitButtonContainer {
  display: flex;
  justify-content: center;
}

.reviewSubmitButtonInnerDiv {
  display: flex;
  width: 60%;
  justify-content: center;
}

.reviewSubmitButton {
  background-color: black;
  border: none;
  border-radius: 4px;
  color: white;
  height: 30px;
  width: 350px;
  /* width: 80%; */
  font-size: 16px;
  font-weight: 1000;
  font-family: 'Poppins', sans-serif;
  margin-top: 1.5rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center;
  text-decoration: none;
  align-items: center;
  display: flex;
  justify-content: center;
}

reviewSubmitButton:active {
  background-color: rgb(182, 43, 64);
}

.mobileHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: solid purple 2px; */
  padding-top: 40px;
}

.mobileReviewBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 0;
  margin-top: 25px;
  /* border: solid green 2px; */
}

.mobileReviewBox p {
  font-size: 18px;
  font-weight: 900;
}

.mobileContactBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1;
}

.tiny {
  font-size: 12px;
  text-decoration: none;
  color: rgb(87, 88, 91);
}

.tiny:hover {
  font-size: 12px;
  text-decoration: underline;
  color: rgb(87, 88, 91);
}

.inlineContainer {
  display: flex;
  align-items: center;
  /* border: solid black 2px; */
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 20px;
}

.starsAdjust {
  margin-top: -3px;
}

.requestHeight {
  line-height: 1.4;
}

.contactSendContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  /* border: solid red 2px; */
}

.contactSendButton {
  background-color: black;
  border: none;
  border-radius: 4px;
  color: white;
  height: 40px;
  width: 160px;
  font-size: 16px;
  font-weight: 1000;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
}

.contactSendButton:hover {
}

.contactSendButton:hover {
  background-color: rgb(240, 50, 77);
}

.contactSendButton:active {
  background-color: rgb(182, 43, 64);
}

.veteransContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10rem 0 15rem 0;
}

.imgContainer {
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  width: 90%;
  margin-top: 5rem;
  margin-left: 4rem;
  padding-bottom: 3rem;
}

.buttonBox {
  display: flex;
  justify-content: space-between;
  width: 90%;
  /* padding-left: 10px; */
  /* padding-right: 10px; */
}



.finePrintMargin {
  font-size: 8px;
  margin-top: -20px;
}

.finePrint {
font-size: 8px;
}

/* .aboutUsContainer {
  margin: 1rem;
  margin-top: -2rem;
  margin-left: 3rem;
} */

.aboutUsTopPadding {
  margin-left: 10rem;
}

.aboutUsTopPadding a {
  /* margin-left: 0; */
}

.aboutButtons {
  background-color: black;
  border: none;
  border-radius: 4px;
  color: white;
  /* padding: 13px 20px 30px 20px; */
  height: 40px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* width: 20%; */
  font-size: 18px;
  /* border: solid pink 3px; */
  font-family: 'Quicksand', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
}

.servicesBody {
  margin-top: 20px;
  margin-left: 60px;
  width: 100%;

}





.aboutButtons:hover {
  background-color: rgb(240, 50, 77);
}

.aboutButtons:active {
  background-color: rgb(182, 43, 64);
}

@media (max-width: 1450px) {
  .bottomRightHalfDiv {
    padding-left: 100px;
  }

  .nav-link {
    font-size: 13px;
    /* border: solid blue 3px; */
  }
  .dropdownServicesButton {
    display: flex;
    font-size: 13px;
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    /* border: solid red 3px; */
    /* padding: 0; */
    /* margin-top: 0; */
    /* height: 100%; */
  
  }
}

@media (max-width: 1250px) {
  /* .bottomRightHalfDiv {
    padding-left: 100px;
  } */

  .nav-link {
    font-size: 11px;
    /* border: solid blue 3px; */
  }
  .dropdownServicesButton {
    /* display: flex; */
    font-size: 11px;
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    /* border: solid red 3px; */
    /* padding: 0; */
    /* margin-top: 0; */
    /* height: 100%; */
  
  }
}

@media (max-width: 1120px) {

  .nav-link {
    font-size: 10px;
  }
  
  .dropdownServicesButton {
    font-size: 10px;
  }
}



@media (max-width: 1090px) {
  .bottomRightHalfDiv {
    margin: 0;
    padding-top: 0;
    padding-left: 0;
    margin-left: 0;
    padding: 0;
    margin: 0;
    /* border: solid green 2px; */
    justify-content: flex-start;
   }
}
